import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecipeList from "../components/recipeList"
import CategoryNav from "../components/categoryNav"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <CategoryNav />
      <RecipeList />
    </Layout>
  )
}

export default IndexPage
